import React from "react";
import { Avatar } from "@chakra-ui/core";
import UserType from "./userType";

const UserAvatar = ({user, ...props}) => {
  const otherProps = {
    ...props
  };
  if (user) {
    otherProps.name = user.displayName || user.email;
    if (user.photoURL) {
      otherProps.src = user.photoURL;
    }
  }
  return (
    <Avatar
      size="sm"
      mr={2}
      {...otherProps}
    />
  );
};

UserAvatar.propTypes = {
  user: UserType,
};

UserAvatar.defaultProps = {
  user: null,
};

export default UserAvatar;
