import React from "react";
import { Box, Flex, Link, Text, useTheme } from "@chakra-ui/core"
import StoreButton from "./footer/StoreButton";
import { FiMail, FiHeadphones, FiCompass } from "react-icons/fi";
import { useIntl } from "gatsby-plugin-intl";

const Container = props => (
  <Flex
    alignItems="center"
    justify="center"
    flexDirection={["column", "column", "row", "row"]}
    width="full"
    mx="auto"
    px={4}
    {...props}
  />
);

const TextIcon = ({ Icon, text, theme, href, ...props }) => {
  const finalProps = {
    fontSize: "sm",
    color: "gray.500",
    mx: 4,
    mb: 1,
    mt: 2,
    ...props,
  };
  const content = (
    <>
      {!!Icon && (<Icon style={{ display: "inline-block", height: theme.fontSizes.sm }}/>)}
      {` ${text}`}
    </>
  );
  if (href) {
    return (
      <Link isExternal href={href} {...finalProps}>
        {content}
      </Link>
    )
  }
  return (
    <Text {...finalProps}>
      {content}
    </Text>
  );
}

const Footer = ({...rest}) => {
  const theme = useTheme();
  const intl = useIntl();
  return (
    <Box
      borderTopWidth="1px"
      paddingY={5}
      width="full"
      {...rest}
    >
      <Container>
        <Text
          fontWeight="500"
          color="gray.500"
          fontSize="xs"
          m={2}
        >
          {intl.formatMessage({ id: "footer.better" })}
        </Text>
        <StoreButton
          width="118px"
          height="35px"
          store="ios"
          locale={intl.locale}
          url="https://apps.apple.com/co/app/maclic/id1476756609?mt=8"
        />
        <StoreButton
          width="135px"
          height="35px"
          store="android"
          locale={intl.locale}
          url="https://play.google.com/store/apps/details?id=co.com.soluciones404.maclic&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        />
      </Container>
      <Container>
        <TextIcon Icon={FiCompass} text="https://maclic.co" href="https://maclic.co" theme={theme} />
        <TextIcon Icon={FiMail} text="hola@maclic.co" href="mailto:hola@maclic.co" theme={theme} />
        <TextIcon Icon={FiHeadphones} text="+57 300 204 4890" href="tel:+573002044890" theme={theme} />
        <Text
          textAlign="center"
          d="inline-block"
          fontSize="sm"
          color="gray.500"
          mx={4}
          mb={1}
          mt={2}
        >
          {intl.formatMessage({ id: "footer.made" })}
          <Link isExternal href="https://roldanilloeasy.com">Roldanillo Easy</Link>
          {intl.formatMessage({ id: "footer.colombia" })}
        </Text>
      </Container>
    </Box>
  );
};

export default Footer;
