import React from "react";
import * as PropTypes from "prop-types";
import { Link } from "gatsby-plugin-intl";
import { PseudoBox, Button } from "@chakra-ui/core";
import { TiThLarge } from "react-icons/ti";
import {
  GiHamburger,
} from "react-icons/gi";
import {
  FaMotorcycle
} from "react-icons/fa";
import LoginButton from "../login/loginbutton";
import UserType from "../login/userType";
import { useIntl } from "gatsby-plugin-intl";

const Links = ({ user, horizontal, closeDrawer, ...props }) => {
  const intl = useIntl();
  return (
    <PseudoBox {...props}>
      <PseudoBox ml={horizontal ? 2 : 0 }>
        <Button
          w={horizontal ? "auto" : "100%"}
          aria-label="Rolda Burguer"
          leftIcon={GiHamburger}
          variant="ghost"
          d={{ xl: 'inline-flex', base: horizontal ? 'none' : 'inline-flex' }}
          color="current"
          fontSize="md"
          width="100%"
          as={Link}
          to="/rolda-burger"
        >
          Rolda Burguer
        </Button>
        <Button
          w={horizontal ? "auto" : "100%"}
          aria-label="Home Service"
          leftIcon={FaMotorcycle}
          variant="ghost"
          color="current"
          fontSize="lg"
          d={{ xl: 'inline-flex', base: horizontal ? 'none' : 'inline-flex' }}
          width="100%"
          as={Link}
          to="/home-service"
        >
          {intl.formatMessage({ id: "homeservice.title" })}
        </Button>
      </PseudoBox>
      <PseudoBox ml={horizontal ? 2 : 0 }>
        <Link to="/places">
          <Button
            aria-label="Navigate"
            leftIcon={TiThLarge}
            variant="ghost"
            color="current"
            d={{ base: 'inline-flex', lg: horizontal ? 'inline-flex' : 'none'}}
            fontSize="md"
            width="100%"
          >
            {intl.formatMessage({ id: "header.navigate" })}
          </Button>
        </Link>
      </PseudoBox>
      <LoginButton
        closeDrawer={closeDrawer}
        horizontal={horizontal}
        user={user}
        d={{ base: 'inline-flex', lg: horizontal ? 'inline-flex' : 'none'}}
      />
    </PseudoBox>
  );
}

Links.propTypes = {
  user: UserType,
  horizontal: PropTypes.bool,
  closeDrawer: PropTypes.func,
};

Links.defaultProps = {
  user: null,
  horizontal: false,
  closeDrawer: null,
};

export default Links;
