import React from "react";
import * as PropTypes from "prop-types";
import { Box } from "@chakra-ui/core";
import Imgix from "react-imgix";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import "./maclicImage.css";

const DOMAIN = "https://maclic.imgix.net";
const PREFIX = "/images";

export const buildURL = src =>
  encodeURI(`${DOMAIN}${src.replace(PREFIX, "")}`);

const SIZES = {
  preview: {
    sizes: "(min-width: 480px) 30vw, 50vw",
    ar: "16:14",
  },
  previewThird: {
    sizes: "(min-width: 480px) 25vw, 70vw",
    ar: "16:12",
  },
  fullWidth: {
    sizes: "(min-width: 480px) 60vw, 50vw",
    ar: "16:10",
  },
  product: {
    sizes: "(min-width: 480px) 50vw, 50vw",
    ar: "16:14",
  },
  pilot: {
    sizes: "(min-width: 480px) 50vw, 60vw",
    ar: "1:1",
  },
  natural: {
    sizes: "(min-width: 480px) 50vw, 60vw",
  }
};

const getSizeProps = (size) => {
  return SIZES[size] || SIZES.fullWidth;
};

const DEFAULT_IMAGE = "/defaults/defaultImage.gif";

const MaclicImage = ({ src, size, w, h, maxWidth, maxHeight, w100, ...rest }) => {
  const url = buildURL(src || DEFAULT_IMAGE);
  const props = getSizeProps(size);
  const wh = (!!w && !!h) ? { w, h } : {};
  const defaultUrl = buildURL(`${DEFAULT_IMAGE}?ar=${props.ar}&fit=crop&crop=edges&auto=format,compress${wh}`);
  return (
    <Box
      className={w100 ? 'maclicImageW100' : 'maclicImage'}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      {...rest}
    >
      <Imgix
        className="lazyload"
        src={url}
        sizes={props.sizes}
        attributeConfig={{
          src: "data-src",
          srcSet: "data-srcset",
          sizes: "data-sizes"
        }}
        imgixParams={{
          fit: "crop",
          ar: props.ar,
          crop: "edges",
          auto: "format,compress",
          ...wh,
        }}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        htmlAttributes={{
          src: defaultUrl
        }}
      />
    </Box>
  );
};

MaclicImage.defaultProps = {
  size: "preview",
  src: null,
  w: null,
  h: null,
};

MaclicImage.propTypes = {
  src: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(SIZES)),
  w: PropTypes.any,
  h: PropTypes.any,
};

export default MaclicImage;
