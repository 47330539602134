import React from "react";
import * as PropTypes from "prop-types";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorMode,
} from "@chakra-ui/core"
import SearchContent from "./content"

const Search = ({ onClose, isOpen, finalRef }) => {
  const { colorMode } = useColorMode();
  const bg = { light: "white", dark: "gray.700" };

  const initialRef = React.useRef();

  const modalProps = {};
  finalRef && (modalProps.finalFocusRef = finalRef);

  return (
    <Modal
      size="xl"
      initialFocusRef={initialRef}
      closeOnOverlayClick={false}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      {...modalProps}
    >
      <ModalOverlay bg={bg[colorMode]} />
      <ModalContent position="absolute" marginY={0} boxShadow="0">
        <ModalCloseButton bg={bg[colorMode]} position="fixed" top="1rem" zIndex={31} />
        <ModalBody width="100%">
          <SearchContent initialRef={initialRef} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

Search.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  finalRef: PropTypes.any,
};

Search.defaultProps = {
  isOpen: false,
  onClose: () => {},
  finalRef: null,
};

export default Search;
