import React from "react";
import * as PropTypes from "prop-types";
import { Box } from "@chakra-ui/core";

const imageLinks = {
  en: {
    ios: 'https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg',
    android: 'https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png',
  },
  es: {
    ios: 'https://linkmaker.itunes.apple.com/images/badges/es-es/badge_appstore-lrg.svg',
    android: 'https://play.google.com/intl/en_us/badges/images/generic/es_badge_web_generic.png',
  }
};

const StoreButton = ({
  store,
  url,
  height,
  width,
  linkStyles,
  linkProps,
  locale,
  display,
  ...props
}) => {
  const defaultLinkStyles = {
    background: `url(${imageLinks[locale][store]}) no-repeat`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'inline-block',
    overflow: 'hidden',
    textDecoration: 'none',
    height: '100%',
    width: '100%',
    padding: '5px',
    ...linkStyles,
  };
  return (
    <Box
      width={width}
      height={height}
      display={display}
      mb={2}
      {...props}
    >
      <a
        style={defaultLinkStyles}
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        {...linkProps}
      >
        &nbsp;
      </a>
    </Box>
  );
};

StoreButton.propTypes = {
  store: PropTypes.oneOf(['ios', 'android']).isRequired,
  url: PropTypes.string.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  display: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  linkStyles: PropTypes.object,
  linkProps: PropTypes.object,
  locale: PropTypes.string,
};

StoreButton.defaultProps = {
  height: 75,
  width: 255,
  locale: "es",
  display: "inline-block",
  linkStyles: {},
  linkProps: {},
};

export default StoreButton;
