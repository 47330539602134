import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorMode,
  ModalHeader,
  ModalFooter,
  Button,
  Tabs,
  TabList,
  Tab,
  FormControl,
  Input,
  FormHelperText,
  Box,
  Alert,
  AlertIcon,
} from "@chakra-ui/core";
import {
  TiSocialFacebook,
  TiSocialGooglePlus,
} from "react-icons/ti";
import {
  passwordReset, signIn, signUp, socialSignIn,
} from "../../util/firebaseFunctions";
import SessionAlert from "./sessionAlert";
import {
  actions,
  GlobalDispatchContext, GlobalStateContext,
} from "../../context/GlobalContextProvider"
import { useIntl } from "gatsby-plugin-intl";

const LoginDialog = () => {
  const intl = useIntl();
  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);
  const { colorMode } = useColorMode();
  const bg = { light: "white", dark: "gray.700" };

  const onClose = () => dispatch({ type: actions.closeDialog });

  const titles = [
    intl.formatMessage({ id: "login.login" }),
    intl.formatMessage({ id: "login.signup" }),
    intl.formatMessage({ id: "login.recoverPassword" })
  ];

  const passwordNotMatch = intl.formatMessage({ id: "login.passwordNotMatch" });
  const passwordReseted = intl.formatMessage({ id: "login.passwordReseted" });
  const passwordResetError = intl.formatMessage({ id: "login.passwordResetError" });

  const [index, setIndex] = React.useState(0);

  const initialRef = React.useRef();
  const [message, setMessage] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [rePassword, setRePassword] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const socialAction = (social) => {
    setMessage(null);
    setLoading(true);
    socialSignIn(social)
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        setMessage({ type: "warning", message: intl.formatMessage({ id: err.message }) });
        setLoading(false);
      });
  };

  const loginAction = () => {
    setLoading(true);
    setMessage(null);
    signIn(email, password)
      .then(() => {
        setLoading(false);
        onClose();
      })
      .catch((err) => {
        setMessage({ type: "warning", message: intl.formatMessage({ id: err.message }) });
        setLoading(false);
      });
  };

  const signUpAction = () => {
    setLoading(true);
    setMessage(null);
    if (password !== rePassword) {
      setMessage({ type: "warning", message: passwordNotMatch });
      setLoading(false);
    } else {
      signUp(email, password)
        .then(() => {
          setLoading(false);
          onClose();
        })
        .catch((err) => {
          setMessage({ type: "warning", message: intl.formatMessage({ id: err.message }) });
          setLoading(false);
        });
    }
  };

  const recoverPasswordAction = () => {
    setLoading(true);
    setMessage(null);
    passwordReset(email, intl.locale)
      .then(() => {
        setLoading(false);
        setMessage({
          type: "info",
          message: passwordReseted,
          title: intl.formatMessage({ id: "login.recoverPassword" }),
        });
      })
      .catch((e) => {
        setMessage({
          type: "warning",
          message: passwordResetError,
          title: intl.formatMessage({ id: "login.recoverPassword" }),
        });
        setLoading(false);
      });
  };

  const action = (event) => {
    !!event && event.preventDefault();
    if (index === 0) {
      loginAction();
    } else if (index === 1) {
      signUpAction();
    } else {
      recoverPasswordAction();
    }
  };

  return (
    <Modal
      size="md"
      initialFocusRef={initialRef}
      onClose={onClose}
      isOpen={state.openDialog}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({ id: "login.myAccount" })}
        </ModalHeader>
        <ModalCloseButton bg={bg[colorMode]} />
        <form onSubmit={action}>
          <ModalBody>
            {state.message && (
              <Alert my={4} status="info">
                <AlertIcon />
                {state.message}
              </Alert>
            )}
            <Tabs onChange={setIndex}>
              <TabList>
                <Tab>{titles[0]}</Tab>
                <Tab>{titles[1]}</Tab>
                <Tab>{titles[2]}</Tab>
              </TabList>
            </Tabs>
            <Box mt={6} px={[2, 2, 6, 8]}>
              <FormControl>
                <Input
                  ref={initialRef}
                  disabled={loading}
                  type="email"
                  id="email"
                  aria-describedby="email-helper-text"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
                <FormHelperText id="email-helper-text">
                  {intl.formatMessage({ id: "login.email" })}
                </FormHelperText>
              </FormControl>
              {index < 2 && (
                <FormControl mt={6}>
                  <Input
                    disabled={loading}
                    type="password"
                    id="password"
                    aria-describedby="password-helper-text"
                    value={password}
                    onChange={event => setPassword(event.target.value)}
                  />
                  <FormHelperText id="password-helper-text">
                    {intl.formatMessage({ id: "login.password" })}
                  </FormHelperText>
                </FormControl>
              )}
              {index === 1 && (
                <FormControl mt={6}>
                  <Input
                    disabled={loading}
                    type="password"
                    id="repassword"
                    aria-describedby="repassword-helper-text"
                    value={rePassword}
                    onChange={event => setRePassword(event.target.value)}
                  />
                  <FormHelperText id="repassword-helper-text">
                    {intl.formatMessage({ id: "login.rePassword" })}
                  </FormHelperText>
                </FormControl>
              )}
            </Box>
            <SessionAlert
              title={intl.formatMessage({ id: "login.loginFailed" })}
              message={message}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variantColor="twitter"
              variant="solid"
              onClick={() => socialAction("google")}
              leftIcon={TiSocialGooglePlus}
              isLoading={loading}
            >
              Google
            </Button>
            <Button
              variantColor="facebook"
              variant="solid"
              ml={2}
              onClick={() => socialAction("facebook")}
              leftIcon={TiSocialFacebook}
              isLoading={loading}
            >
              Facebook
            </Button>
            <Button
              variantColor="cyan"
              variant="solid"
              ml={2}
              isLoading={loading}
              type="submit"
            >
              {titles[index]}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

export default LoginDialog;
