import React from "react";
import { useColorMode } from "@chakra-ui/core";

const useColor = (colorMode, light = "white", dark = "gray.800") => {
  const [render, setRender] = React.useState(null);
  React.useEffect(() => setRender(true), [colorMode]);
  return {
    render,
    color: colorMode === "light" ? light : dark,
  };
}

const useColorWithoutMode = (light, dark) => {
  const { colorMode } = useColorMode();
  return useColor(colorMode, light, dark);
};

export {
  useColorWithoutMode,
  useColor,
};
