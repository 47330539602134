// import { useFirebase } from "maclic-plugin-firebase";
import React from 'react';
import firebase from '../../plugins/gatsby-plugin-firebase';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);
moment.tz.setDefault('America/Bogota');

const initial = moment('2020-08-27 08:00');
const final = moment('2020-08-29 16:00');

const votationRange = moment.range(initial, final);

const signInWithProvider = async (provider) => {
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    return result.user;
  } catch(e) {
    throw new Error(e.message);
  }
}

const signInWithGoogle = () => {
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  return signInWithProvider(googleAuthProvider);
};

const signInWithFacebook = () => {
  const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
  return signInWithProvider(facebookAuthProvider);
}

const socialSignIn = (social) => {
  if (social === "facebook") {
    return signInWithFacebook();
  }
  return signInWithGoogle();
}

const passwordReset = (email, locale = 'es') => {
  firebase.auth().languageCode = locale;
  return firebase.auth().sendPasswordResetEmail(email);
};

const signIn = async (email, password) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch(e) {
    let message;
    switch (e.code) {
      case "auth/invalid-email":
        message = `login.invalidEmail`;
        break;
      case "auth/user-disabled":
        message = `login.userDisabled`;
        break;
      case "auth/user-not-found":
        message = `login.userNotFound`;
        break;
      case "auth/wrong-password":
        message = `login.wrongPassword`;
        break;
      default:
        message = `login.loginError`;
    }
    throw new Error(message);
  }
};

const signUp = async (email, password) => {
  try {
    await firebase.auth().createUserWithEmailAndPassword(email, password);
  } catch(e) {
    let message;
    switch (e.code) {
      case "auth/invalid-email":
        message = `login.invalidEmail`;
        break;
      case "auth/email-already-in-use":
        message = `login.emailAlreadyInUse`;
        break;
      case "auth/weak-password":
        message = `login.weakPassword`;
        break;
      default:
        message = `login.signUpError`;
    }
    throw new Error(message);
  }
};

const getCompetitions = async () => {
  const snapshot = await firebase.firestore()
    .collection("competitions")
    .get();
  const competitions = [];
  snapshot.forEach((doc) => {
    const competition = doc.data();
    competitions.push({
      ...competition,
      id: doc.id,
    });
  });
  return competitions;
};

const uploadFile = (filePath, file, updateCallback, finalCallback) => {
  const uploadTask = firebase.storage().ref().child(filePath).put(file);

  uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
    (snapshot) => {
      const progress = {
        value: ((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      };
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          progress.paused = true;
          break;
        case firebase.storage.TaskState.RUNNING:
          progress.paused = false;
          break;
        default:
          progress.paused = false;
      }
      updateCallback(progress);
    }, (error) => {
      let message;
      switch (error.code) {
        case 'storage/unauthorized':
          message = 'storage.unauthorized';
          break;
        case 'storage/canceled':
          message = 'storage.canceled';
          break;
        // case 'storage/unknown':
        //   result.message = 'Ups! No tienes permisos para cargar esta imagen 🤭.';
        //   break;
        default:
          message = 'storage.defaultError';
      }
      finalCallback(new Error(message));
    }, () => {
      finalCallback(null, filePath);
    });
}

const deleteFile = (reference) => {
  return firebase.storage().ref().child(reference).delete();
}

const registerPilot = async (values) => {
  const { id, ...newValues } = values;
  let ref = firebase.firestore()
    .collection('pilots').doc();
  if (id) {
    ref = firebase.firestore()
      .collection('pilots').doc(id);
  }
  await ref.set(newValues);
};

const totalVotesReference = () => {
  return firebase.firestore()
    .collection('counters')
    .doc('roldaburger2021');
};

const voteBurger = async (node, number, user, currentFingerPrint, fingerPrintNew) => {
  const {name, place, id} = node;
  let description = name;
  if (place) {
    description = `${description} por ${place.name}`;
  }
  let attribute;
  switch (number) {
    case 1:
      attribute = 'first';
      break;
    case 2:
      attribute = 'second';
      break;
    default:
      attribute = 'third';
  }
  const vote = {
    email: user.email,
  };
  vote[attribute] = id;
  vote[`${attribute}Description`] = description;
  vote[`${attribute}Time`] = firebase.firestore.FieldValue.serverTimestamp();
  await firebase.firestore()
    .collection('roldaburger2021')
    .doc(user.uid)
    .set(vote, {merge: true});
  
  await totalVotesReference()
    .update('count', firebase.firestore.FieldValue.increment(1));

  await registerFingerPrint(currentFingerPrint, fingerPrintNew);
  return vote;
};

const useMyVote = (user) => {
  const [vote, setVote] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (user) {
      unsubscribe = firebase.firestore()
      .collection('roldaburger2021')
      .doc(user.uid)
      .onSnapshot((snap) => {
        if (snap.exists) {
          setVote({
            ...snap.data(),
            id: snap.id,
          });
        }
      }, (err) => console.warn(err));
    }
    return () => unsubscribe && unsubscribe();
  }, [user]);
  return vote;
};

const getPilotRecords = async (uid) => {
  const snapshot = await firebase.firestore()
    .collection("pilots")
    .where('createdBy', '==', uid)
    .get();
  const competitions = [];
  snapshot.forEach((doc) => {
    const competition = doc.data();
    competitions.push({
      ...competition,
      id: doc.id,
    });
  });
  return competitions;
};

const getPilotRecord = async (uid, id) => {
  const snapshot = await firebase.firestore()
    .collection("pilots")
    .doc(id)
    .get();
  if (snapshot.exists) {
    const record = {
      ...snapshot.data(),
      id: snapshot.id,
    };
    if (record.createdBy === uid) {
      return record;
    }
  }
  return null;
};

const deletePilot = async (record) => {
  if (record.photo !== "") {
    try {
      await deleteFile(record.photo);
    } catch(e) {
      console.warn(e.message);
    }
  }
  if (record.passportPhoto !== "") {
    try {
      await deleteFile(record.passportPhoto);
    } catch(e) {
      console.warn(e.message);
    }
  }
  await firebase.firestore()
    .collection("pilots")
    .doc(record.id)
    .delete();
};

const useRegisterScreen = (name) => {
  React.useEffect(() => {
    firebase.analytics().setCurrentScreen(name);
  }, []);
};

const isVotationOpen = () => {
  const current = moment(firebase.firestore.Timestamp.now().toDate());
  console.warn(current.toString());
  return current.within(votationRange);
};

const useUser = () => {
  const [user, setUser] = React.useState(null);
  React.useEffect(() => {
    let unsubscribe;
    if (firebase) {
      unsubscribe = firebase.auth()
        .onAuthStateChanged((u) => setUser(u));
    }
    return () => !!unsubscribe && unsubscribe();
  }, []);
  return user;
};

const registerFingerPrint = async (fingerprint, isNew) => {
  const reference = firebase.firestore().collection('fingerprints2021').doc(fingerprint);
  if (isNew) {
    await reference.set({
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      votes: 1,
    });
  } else {
    await reference.update({
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      votes: firebase.firestore.FieldValue.increment(1),
    });
  }
};

const getFingerPrint = async (fingerprint) => {
  const snapshot = await firebase.firestore()
    .collection('fingerprints2021')
    .doc(fingerprint)
    .get();
  if (snapshot.exists) {
    return snapshot.data();
  }
  return null;
};

export {
  signInWithGoogle,
  signInWithFacebook,
  socialSignIn,
  signIn,
  signUp,
  getCompetitions,
  uploadFile,
  registerPilot,
  getPilotRecords,
  getPilotRecord,
  deleteFile,
  deletePilot,
  voteBurger,
  useMyVote,
  totalVotesReference,
  passwordReset,
  useRegisterScreen,
  isVotationOpen,
  useUser,
  registerFingerPrint,
  getFingerPrint,
};
