import React from "react";
import { Link } from "gatsby-plugin-intl";
import {
  PseudoBox,
  Box,
  Flex,
  IconButton,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/core";
import Logo from "./icons/logo";
import { useColor } from "../util/colorMode";
import Links from "./nav/links";
import Search from "./search";
import MobileNav from "./nav/mobileNav";
import LoginDialog from "./login/loginDialog";
import ProfileDialog from "./login/profileDialog";
import { useIntl } from "gatsby-plugin-intl";
import I18n from "./i18n";
import { useUser } from "../util/firebaseFunctions";

// const Container = props => (
//   <Box width="full" maxWidth="1280px" mx="auto" px={0} {...props} />
// );

const Header = props => {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useUser();
  const { render, color } = useColor(colorMode);
  const intl = useIntl();
  return render && (
    <PseudoBox
      pos="fixed"
      as="header"
      top="0"
      zIndex="6"
      backgroundColor={color}
      left="0"
      right="0"
      borderBottomWidth="1px"
      width="full"
      height="4rem"
      {...props}
    >
      <Flex size="100%" px="6" align="center" justify="space-between">
        <Link to="/" aria-label={intl.formatMessage({ id: "header.back" })}>
          <Box
            d="block"
          >
            <Logo h="3rem" />
          </Box>
        </Link>
        <Flex align="center" color="gray.500">
          <IconButton
            aria-label={intl.formatMessage({ id: "search.search" })}
            icon="search"
            variant="ghost"
            color="current"
            ml="2"
            fontSize="20px"
            onClick={() => onOpen()}
          />
          <IconButton
            aria-label={`Switch to ${
              colorMode === "light" ? "dark" : "light"
            } mode`}
            variant="ghost"
            color="current"
            ml="2"
            fontSize="20px"
            onClick={toggleColorMode}
            icon={colorMode === "light" ? "moon" : "sun"}
          />
          <I18n size="sm" ml={2} variant="ghost" fontSize="md" />
          <Links
            user={user}
            display={{ xs: "none", lg: "inline-flex"}}
            horizontal
          />
          <MobileNav user={user} />
        </Flex>
      </Flex>
      <Search onClose={onClose} isOpen={isOpen} />
      {!user && <LoginDialog />}
      {!!user && <ProfileDialog user={user} />}
    </PseudoBox>
  );
};

export default Header;
