import React from "react";
import * as PropTypes from "prop-types";
import { Link } from "gatsby";
import { Box, Text } from "@chakra-ui/core";
import { useIntl } from "gatsby-plugin-intl";
import { getAttributeTranslated } from "../../util/entities"

const Results = ({ data, onClose, ...props }) => {
  const intl = useIntl();
  if (data) {
    const { query, results } = data;
    if (query.trim().length > 0 && results && results.length === 0) {
      return (
        <Box {...props}>
          <Text textAlign="center" mt={5} fontSize="xl">
            {intl.formatMessage({ id: "search.noResults" })}
          </Text>
        </Box>
      );
    } else if (query.trim().length > 0) {
      const by = intl.formatMessage({ id: "util.by" });
      return (
        <Box {...props}>
          <Text d="block" mb={2} fontSize="xs">
            {`${results.length} ${intl.formatMessage({ id: "search.results" })}.`}
          </Text>
          {results.map(item => (
            <Link key={item.path} to={item.path} onClick={onClose}>
              <Text py={5} borderTopWidth={1} fontSize="md" d="block">
                {getAttributeTranslated(item, 'title', intl.locale)}
                {
                  item.parent
                  ? ` ${by} ${getAttributeTranslated(item, 'parent', intl.locale)}`
                  : null
                }
              </Text>
            </Link>
          ))}
        </Box>
      );
    }
  }
  return null;
};

Results.propTypes = {
  data: PropTypes.shape({
    query: PropTypes.string,
    results: PropTypes.arrayOf(PropTypes.object),
  }),
  onClose: PropTypes.func,
};

Results.defaultProps = {
  data: null,
  onClose: () => {},
};

export default Results;
