import React from "react";
import { Button, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/core"
import { useIntl } from "gatsby-plugin-intl";
import { changeLocale } from "gatsby-plugin-intl";

const LANGUAGES = {
  es: {
    value: "es",
    label: "Español",
  },
  en: {
    value: "en",
    label: "English",
  }
};

const I18n = (props) => {
  const intl = useIntl();
  const switchLang = (l) => changeLocale(l);
  return (
    <Menu>
      <MenuButton as={Button} rightIcon="chevron-down" {...props}>
        {LANGUAGES[intl.locale].value}
      </MenuButton>
      <MenuList>
        <MenuItem disabled>
          {intl.formatMessage({ id: "header.switchLang" })}
        </MenuItem>
        {Object.values(LANGUAGES).map((item) => (
          <MenuItem key={item.value} onClick={() => switchLang(item.value)}>
            {item.label}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default I18n;
