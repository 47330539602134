/** @jsx jsx */
import { useColorMode, Box } from "@chakra-ui/core";
import { jsx } from "@emotion/core";

const Logo = props => {
  const { colorMode } = useColorMode();

  return colorMode === "light" ? (
    <Box
      as="svg"
      height="12"
      width="auto"
      viewBox="0 0 438.2 207.1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#707070" d="M285.5,91.8c3.2,3.6,4.8,8,4.8,13.1v15.5c0,0.8-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9
	c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2V105c0-3.4-1-6.4-3-8.8c-2-2.4-4.4-3.6-7.2-3.6c-2.8,0-5.2,1.2-7.2,3.6
	c-2,2.4-3,5.4-3,8.8v15.5c0,0.8-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2v-15.5
	c0-3.4-1-6.3-3-8.8c-2-2.4-4.4-3.6-7.2-3.6s-5.2,1.2-7.2,3.6c-2,2.4-3,5.4-3,8.8v15.5c0,0.9-0.3,1.6-0.9,2.2
	c-0.6,0.6-1.3,0.9-2.2,0.9c-0.9,0-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2v-31c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9
	c0.8,0,1.6,0.3,2.2,0.9c0.6,0.6,0.9,1.3,0.9,2.2v1c3-2.7,6.4-4,10.1-4c5.5,0,10,2.6,13.2,7.7c3.2-5.2,7.6-7.7,13.2-7.7
	C278.5,86.4,282.3,88.2,285.5,91.8z M332.1,87.3c0.6,0.6,0.9,1.3,0.9,2.2v31c0,0.9-0.3,1.6-0.9,2.2c-0.6,0.6-1.3,0.9-2.2,0.9
	s-1.6-0.3-2.2-0.9c-0.6-0.6-0.9-1.3-0.9-2.2v-1.6c-3.4,3.1-7.5,4.7-12.1,4.7c-5,0-9.3-1.8-12.9-5.4c-3.6-3.6-5.4-8-5.4-13.1
	c0-5.1,1.8-9.5,5.4-13.1c3.6-3.6,7.9-5.5,12.9-5.5c4.6,0,8.6,1.6,12.1,4.7v-1.6c0-0.9,0.3-1.6,0.9-2.2c0.6-0.6,1.3-0.9,2.2-0.9
	S331.6,86.7,332.1,87.3z M326.9,105c0-3.4-1.2-6.4-3.5-8.8c-2.4-2.4-5.2-3.6-8.6-3.6c-3.3,0-6.2,1.2-8.6,3.6
	c-2.4,2.4-3.6,5.4-3.6,8.8c0,3.4,1.2,6.3,3.6,8.8c2.4,2.4,5.2,3.6,8.6,3.6c3.3,0,6.2-1.2,8.6-3.6C325.7,111.3,326.9,108.4,326.9,105
	z M356.6,92.6c4.1,0,7.3,1.7,9.8,5.1c0.5,0.7,1.2,1.1,2,1.3c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1.2-0.2,1.7-0.6c0.7-0.5,1.1-1.2,1.3-2
	c0-0.2,0.1-0.4,0.1-0.6c0-0.6-0.2-1.2-0.6-1.7c-3.7-5.1-8.6-7.7-14.8-7.7c-5,0-9.3,1.8-12.9,5.5c-3.6,3.6-5.4,8-5.4,13.1
	c0,5.1,1.8,9.5,5.4,13.1c3.6,3.6,7.9,5.4,12.9,5.4c6.2,0,11.1-2.6,14.8-7.7c0.4-0.5,0.6-1.1,0.6-1.7c0-0.2,0-0.4-0.1-0.6
	c-0.1-0.9-0.5-1.5-1.3-2c-0.5-0.4-1.1-0.6-1.7-0.6c-0.2,0-0.4,0-0.6,0.1c-0.9,0.1-1.5,0.5-2,1.2c-2.4,3.4-5.7,5.1-9.8,5.1
	c-3.3,0-6.2-1.2-8.6-3.6c-2.4-2.4-3.6-5.4-3.6-8.8c0-3.4,1.2-6.4,3.6-8.8C350.4,93.8,353.2,92.6,356.6,92.6z M380.8,73.8
	c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2v43.5c0,0.9,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9
	c0.6-0.6,0.9-1.3,0.9-2.2V76.9c0-0.9-0.3-1.6-0.9-2.2C382.3,74.1,381.6,73.8,380.8,73.8z M395.3,86.4c-0.9,0-1.6,0.3-2.2,0.9
	c-0.6,0.6-0.9,1.3-0.9,2.2v31c0,0.9,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9s1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2v-31
	c0-0.9-0.3-1.6-0.9-2.2C396.9,86.7,396.2,86.4,395.3,86.4z M397.3,74.4c-0.6-0.3-1.3-0.5-1.9-0.5s-1.3,0.2-1.9,0.5
	c-0.6,0.3-1.1,0.8-1.5,1.5s-0.5,1.3-0.5,2c0,0.7,0.2,1.3,0.5,2s0.8,1.1,1.5,1.5c0.6,0.4,1.3,0.5,2,0.5s1.3-0.2,2-0.5
	c0.6-0.4,1.1-0.8,1.5-1.5c0.4-0.6,0.5-1.3,0.5-2c0-0.7-0.2-1.3-0.5-2C398.4,75.2,397.9,74.7,397.3,74.4z M436.9,111.6
	c-0.5-0.4-1.1-0.6-1.7-0.6c-0.2,0-0.4,0-0.6,0.1c-0.9,0.1-1.5,0.5-2,1.2c-2.4,3.4-5.7,5.1-9.8,5.1c-3.3,0-6.2-1.2-8.6-3.6
	c-2.4-2.4-3.6-5.4-3.6-8.8c0-3.4,1.2-6.4,3.6-8.8c2.4-2.4,5.2-3.6,8.6-3.6c4.1,0,7.3,1.7,9.8,5.1c0.5,0.7,1.2,1.1,2,1.3
	c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1.2-0.2,1.7-0.6c0.7-0.5,1.1-1.2,1.3-2c0-0.2,0.1-0.4,0.1-0.6c0-0.6-0.2-1.2-0.6-1.7
	c-3.7-5.1-8.6-7.7-14.8-7.7c-5,0-9.3,1.8-12.9,5.5c-3.6,3.6-5.4,8-5.4,13.1c0,5.1,1.8,9.5,5.4,13.1c3.6,3.6,7.9,5.4,12.9,5.4
	c6.2,0,11.1-2.6,14.8-7.7c0.4-0.5,0.6-1.1,0.6-1.7c0-0.2,0-0.4-0.1-0.6C438,112.7,437.6,112,436.9,111.6z"/>
      <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="5.6601" y1="3.7215" x2="206.2696" y2="204.331">
        <stop  offset="0" stopColor="#5BC3BF"/>
        <stop  offset="0.8857" stopColor="#008BA9" />
      </linearGradient>
      <path fill="url(#SVGID_1_)" d="M104.1,66c49.1,0,88.9,39.8,88.9,88.9c0,1.3-0.1,2.5-0.2,3.8c9.8-15.9,15.5-34.5,15.5-54.5
	C208.3,46.7,161.7,0,104.1,0C46.5,0-0.2,46.7-0.2,104.2c0,20,5.7,38.6,15.5,54.5c-0.1-1.3-0.2-2.5-0.2-3.8
	C15.2,105.9,55,66,104.1,66"/>
      <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="-21.1201" y1="37.2035" x2="189.8116" y2="248.1352">
        <stop  offset="0.2831" stopColor="#5BC3BF" />
        <stop  offset="0.909" stopColor="#008BA9" />
      </linearGradient>
      <path fill="url(#SVGID_2_)" d="M185.5,167.8c-0.5-0.8-0.9-1.5-1.4-2.3c-1.3-2.4-2.5-4.8-3.8-7.3c-3.1-6.1-6-11.8-9.9-16.8
	c-5.5-7-12.7-11.2-19.1-10.8c-8.5,0.3-15.7,7.6-20.2,13.7c-2,2.7-3.8,5.5-5.7,8.3c-3.9,6-8,12.1-13.5,17.3
	c-2.2,2.1-5.7,4.8-10.5,4.5c-6.7-0.4-10.1-6.4-11.5-8.9c-5.1-9-10.7-18.4-18.8-24.7c-8-6.2-17.9-8.3-25.2-5.2
	c-9.7,4-14.8,15.8-19.2,27.5l-2.6,6.8c19.1,22.8,47.7,37.3,79.8,37.3C137.1,207.1,166.5,191.7,185.5,167.8"/>
    </Box>
  ) : (
    <Box
      as="svg"
      height="12"
      width="auto"
      viewBox="0 0 438.2 207.1"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="none" d="M314.8,92.6c-3.3,0-6.2,1.2-8.6,3.6c-2.4,2.4-3.6,5.4-3.6,8.8c0,3.4,1.2,6.3,3.6,8.8c2.4,2.4,5.2,3.6,8.6,3.6
		c3.3,0,6.2-1.2,8.6-3.6c2.3-2.4,3.5-5.4,3.5-8.8c0-3.4-1.2-6.4-3.5-8.8C321,93.8,318.1,92.6,314.8,92.6z"/>
      <path fill="#FFFFFF" d="M274,86.4c-5.5,0-9.9,2.6-13.2,7.7c-3.3-5.2-7.7-7.7-13.2-7.7c-3.8,0-7.2,1.3-10.1,4v-1
		c0-0.9-0.3-1.6-0.9-2.2c-0.6-0.6-1.3-0.9-2.2-0.9c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2v31c0,0.9,0.3,1.6,0.9,2.2
		c0.6,0.6,1.3,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2V105c0-3.4,1-6.4,3-8.8c2-2.4,4.4-3.6,7.2-3.6
		s5.2,1.2,7.2,3.6c2,2.4,3,5.4,3,8.8v15.5c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9
		c0.6-0.6,0.9-1.3,0.9-2.2v-15.5c0-3.4,1-6.3,3-8.8c2-2.4,4.4-3.6,7.2-3.6c2.8,0,5.2,1.2,7.2,3.6c2,2.4,3,5.4,3,8.8v15.5
		c0,0.8,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2V105c0-5.1-1.6-9.5-4.8-13.1
		C282.3,88.2,278.5,86.4,274,86.4z"/>
      <path fill="#FFFFFF" d="M330,86.4c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2v1.6c-3.4-3.1-7.5-4.7-12.1-4.7
		c-5,0-9.3,1.8-12.9,5.5c-3.6,3.6-5.4,8-5.4,13.1c0,5.1,1.8,9.5,5.4,13.1c3.6,3.6,7.9,5.4,12.9,5.4c4.6,0,8.6-1.6,12.1-4.7v1.6
		c0,0.9,0.3,1.6,0.9,2.2c0.6,0.6,1.3,0.9,2.2,0.9s1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2v-31c0-0.9-0.3-1.6-0.9-2.2
		C331.6,86.7,330.8,86.4,330,86.4z M323.4,113.7c-2.4,2.4-5.2,3.6-8.6,3.6c-3.3,0-6.2-1.2-8.6-3.6c-2.4-2.4-3.6-5.4-3.6-8.8
		c0-3.4,1.2-6.4,3.6-8.8c2.4-2.4,5.2-3.6,8.6-3.6c3.3,0,6.2,1.2,8.6,3.6c2.3,2.4,3.5,5.4,3.5,8.8
		C326.9,108.4,325.7,111.3,323.4,113.7z"/>
      <path fill="#FFFFFF" d="M356.6,92.6c4.1,0,7.3,1.7,9.8,5.1c0.5,0.7,1.2,1.1,2,1.3c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1.2-0.2,1.7-0.6
		c0.7-0.5,1.1-1.2,1.3-2c0-0.2,0.1-0.4,0.1-0.6c0-0.6-0.2-1.2-0.6-1.7c-3.7-5.1-8.6-7.7-14.8-7.7c-5,0-9.3,1.8-12.9,5.5
		c-3.6,3.6-5.4,8-5.4,13.1c0,5.1,1.8,9.5,5.4,13.1c3.6,3.6,7.9,5.4,12.9,5.4c6.2,0,11.1-2.6,14.8-7.7c0.4-0.5,0.6-1.1,0.6-1.7
		c0-0.2,0-0.4-0.1-0.6c-0.1-0.9-0.5-1.5-1.3-2c-0.5-0.4-1.1-0.6-1.7-0.6c-0.2,0-0.4,0-0.6,0.1c-0.9,0.1-1.5,0.5-2,1.2
		c-2.4,3.4-5.7,5.1-9.8,5.1c-3.3,0-6.2-1.2-8.6-3.6c-2.4-2.4-3.6-5.4-3.6-8.8c0-3.4,1.2-6.4,3.6-8.8
		C350.4,93.8,353.2,92.6,356.6,92.6z"/>
      <path fill="#FFFFFF" d="M380.8,73.8c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2v43.5c0,0.9,0.3,1.6,0.9,2.2
		c0.6,0.6,1.3,0.9,2.2,0.9c0.8,0,1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2V76.9c0-0.9-0.3-1.6-0.9-2.2
		C382.3,74.1,381.6,73.8,380.8,73.8z"/>
      <path fill="#FFFFFF" d="M395.3,86.4c-0.9,0-1.6,0.3-2.2,0.9c-0.6,0.6-0.9,1.3-0.9,2.2v31c0,0.9,0.3,1.6,0.9,2.2
		c0.6,0.6,1.3,0.9,2.2,0.9s1.6-0.3,2.2-0.9c0.6-0.6,0.9-1.3,0.9-2.2v-31c0-0.9-0.3-1.6-0.9-2.2C396.9,86.7,396.2,86.4,395.3,86.4z"
      />
      <path fill="#FFFFFF" d="M397.3,74.4c-0.6-0.3-1.3-0.5-1.9-0.5s-1.3,0.2-1.9,0.5c-0.6,0.3-1.1,0.8-1.5,1.5s-0.5,1.3-0.5,2
		c0,0.7,0.2,1.3,0.5,2s0.8,1.1,1.5,1.5c0.6,0.4,1.3,0.5,2,0.5s1.3-0.2,2-0.5c0.6-0.4,1.1-0.8,1.5-1.5c0.4-0.6,0.5-1.3,0.5-2
		c0-0.7-0.2-1.3-0.5-2C398.4,75.2,397.9,74.7,397.3,74.4z"/>
      <path fill="#FFFFFF" d="M436.9,111.6c-0.5-0.4-1.1-0.6-1.7-0.6c-0.2,0-0.4,0-0.6,0.1c-0.9,0.1-1.5,0.5-2,1.2
		c-2.4,3.4-5.7,5.1-9.8,5.1c-3.3,0-6.2-1.2-8.6-3.6c-2.4-2.4-3.6-5.4-3.6-8.8c0-3.4,1.2-6.4,3.6-8.8c2.4-2.4,5.2-3.6,8.6-3.6
		c4.1,0,7.3,1.7,9.8,5.1c0.5,0.7,1.2,1.1,2,1.3c0.2,0,0.4,0.1,0.6,0.1c0.6,0,1.2-0.2,1.7-0.6c0.7-0.5,1.1-1.2,1.3-2
		c0-0.2,0.1-0.4,0.1-0.6c0-0.6-0.2-1.2-0.6-1.7c-3.7-5.1-8.6-7.7-14.8-7.7c-5,0-9.3,1.8-12.9,5.5c-3.6,3.6-5.4,8-5.4,13.1
		c0,5.1,1.8,9.5,5.4,13.1c3.6,3.6,7.9,5.4,12.9,5.4c6.2,0,11.1-2.6,14.8-7.7c0.4-0.5,0.6-1.1,0.6-1.7c0-0.2,0-0.4-0.1-0.6
		C438,112.7,437.6,112,436.9,111.6z"/>
      <path fill="#FFFFFF" d="M104.2,66c49.1,0,88.9,39.8,88.9,88.9c0,1.3-0.1,2.5-0.2,3.8c9.8-15.9,15.5-34.5,15.5-54.5
		C208.5,46.7,161.8,0,104.2,0C46.7,0,0,46.7,0,104.2c0,20,5.7,38.6,15.5,54.5c-0.1-1.3-0.2-2.5-0.2-3.8C15.3,105.9,55.1,66,104.2,66
		"/>
      <path fill="#FFFFFF" d="M185.6,167.8c-0.5-0.8-0.9-1.5-1.4-2.3c-1.3-2.4-2.5-4.8-3.8-7.3c-3.1-6.1-6-11.8-9.9-16.8
		c-5.5-7-12.7-11.2-19.1-10.8c-8.5,0.3-15.7,7.6-20.2,13.7c-2,2.7-3.8,5.5-5.7,8.3c-3.9,6-8,12.1-13.5,17.3
		c-2.2,2.1-5.7,4.8-10.5,4.5c-6.7-0.4-10.1-6.4-11.5-8.9c-5.1-9-10.7-18.4-18.8-24.7c-8-6.2-17.9-8.3-25.2-5.2
		c-9.7,4-14.8,15.8-19.2,27.5l-2.6,6.8c19.1,22.8,47.7,37.3,79.8,37.3C137.2,207.1,166.6,191.7,185.6,167.8"/>
    </Box>
  );
};

export default Logo;