import React from "react";
import * as PropTypes from "prop-types";
import { graphql, StaticQuery } from "gatsby";
import {
  Box, Icon, Input, InputGroup, InputRightElement, useColorMode,
} from "@chakra-ui/core";
import Logo from "../icons/logo";
import Results from "./results";
import { Index } from "elasticlunr";
import { useIntl } from "gatsby-plugin-intl";

const SearchComponent = ({ data, initialRef, ...props }) => {
  const intl = useIntl();
  const { colorMode } = useColorMode();
  const bg = { light: "white", dark: "gray.700" };
  const [state, setState] = React.useState({ query: '', results: [] });

  const [index, setIndex] = React.useState(null);
  const getOrCreateIndex = () => index || Index.load(data.siteSearchIndex.index);
  const search = (evt) => {
    const query = evt.target.value
    const theIndex = getOrCreateIndex();
    setIndex(theIndex);
    setState({
      query,
      results: theIndex
        .search(query, { expand: true })
        .map(({ ref }) => theIndex.documentStore.getDoc(ref)),
    });
  };

  return (
    <>
      <Box
        maxWidth={["unset", "unset", "33rem", "33rem"]}
        width={["initial", "initial", "inherit", "inherit"]}
        position="fixed"
        left={["2rem", "2rem", "unset", "unset"]}
        right={["2rem", "2rem", "unset", "unset"]}
        top={0}
        pt="1rem"
        bg={bg[colorMode]}
        zIndex={30}
        pb="0.5rem"
        {...props}
      >
        <Logo mb="1rem" h="3rem" mx="auto" />
        <InputGroup>
          <Input
            ref={initialRef}
            value={state.query}
            onChange={search}
            variant="filled"
            placeholder={intl.formatMessage({ id: "search.placeholder" })}
            size="lg"
          />
          <InputRightElement children={<Icon name="search" />} />
        </InputGroup>
      </Box>
      <Results
        data={state}
        marginTop="8rem"
        paddingX="2rem"
        width="100%"
        maxWidth="32rem"
        zIndex={20}
      />
    </>
  );
};

const query = graphql`
  query SearchIndexQuery {
    siteSearchIndex {
      index
    }
  }
`;

const SearchContent = (props) => (
  <StaticQuery query={query} render={data => (
    <SearchComponent data={data} {...props} />
  )} />
);

SearchContent.propTypes = {
  initialRef: PropTypes.any.isRequired,
};

export default SearchContent;
