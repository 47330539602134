import React from "react";
import * as PropTypes from "prop-types";
import { Button } from "@chakra-ui/core";
import UserAvatar from "./userAvatar";
import UserType from "./userType";
import {
  GlobalDispatchContext, actions,
} from "../../context/GlobalContextProvider";
import { useIntl } from "gatsby-plugin-intl"

const LoginButton = ({ user, closeDrawer, horizontal, ...props }) => {
  const dispatch = React.useContext(GlobalDispatchContext);
  const intl = useIntl();
  return (
    <Button
      aria-label="Login"
      variant="ghost"
      color="current"
      ml={horizontal ? 2 : 0}
      fontSize="md"
      onClick={() => {
        !!closeDrawer && closeDrawer();
        dispatch({ type: actions.openDialog });
      }}
      { ...props }
    >
      <UserAvatar user={user} mr={2} />
      {intl.formatMessage({ id: "header.myAccount" })}
    </Button>
  );
};

LoginButton.propTypes = {
  user: UserType,
  horizontal: PropTypes.bool,
  closeDrawer: PropTypes.func,
};

LoginButton.defaultProps = {
  user: null,
  horizontal: false,
  closeDrawer: () => {},
};

export default LoginButton;
