import React from "react";
import { Helmet } from "react-helmet";
import * as PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { remark } from "remark";
import strip from "strip-markdown";
import { buildURL } from "../images/maclicImage";
import { getAttributeTranslated } from "../../util/entities";
import { useIntl } from "gatsby-plugin-intl";

const getDescription = (description, defaultDescription) => {
  if (description) {
    try {
      const str = new String(remark()
        .use(strip)
        .processSync(description));
      return str.replace(/['”“"\\\r?\n|\r]+/g, '');
    } catch (e) {
      console.error(e);
      return defaultDescription;
    }
  }
  return defaultDescription;
};

const SEO = ({
  title, description, image, pathname, type, location, imageLocal,
}) => {
  const intl = useIntl();
  return (
    <StaticQuery
      query={query}
      render={({
         site: {
           siteMetadata: {
             defaultTitle,
             defaultTitle_en,
             titleTemplate,
             defaultDescription,
             defaultDescription_en,
             siteUrl,
             defaultImage,
           },
         },
       }) => {
        const theDefaultTitle = getAttributeTranslated({
          defaultTitle,
          defaultTitle_en
        }, 'defaultTitle', intl.locale);
        let theImage = `${siteUrl}${defaultImage}`;
        if (image) {
          if (imageLocal) {
            theImage = `${siteUrl}${image}`;
          } else {
            theImage = `https:${buildURL(`${image}`)}?w=600`;
          }
        }
        const seo = {
          title: title || theDefaultTitle,
          description: getDescription(description, getAttributeTranslated({
            defaultDescription,
            defaultDescription_en
          }, 'defaultDescription', intl.locale)),
          image: theImage,
          url: `${siteUrl}${pathname || "/"}`,
          type,
          location,
        };
        const helmetProps = {};
        if (!title) {
          helmetProps.title = theDefaultTitle;
        } else {
          helmetProps.title = seo.title;
          helmetProps.titleTemplate = titleTemplate;
        }
        return (
          <>
            <Helmet {...helmetProps}>
              {seo.image && <meta property="og:image" content={seo.image} />}
              <meta name="image" content={seo.image} />
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.url && <meta property="og:url" content={seo.url} />}
              <meta name="description" content={seo.description} />
              <meta property="fb:app_id" content="2416639735261425" />
              <meta property="og:type" content={seo.type} />
              {seo.description && (
                <meta property="og:description" content={seo.description} />
              )}
              {seo.location && (
                <meta property="place:location:latitude" content={`${seo.location.lat}`} />
              )}
              {seo.location && (
                <meta property="place:location:longitude" content={`${seo.location.lng}`} />
              )}
              <meta name="twitter:card" content="summary_large_image" />
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && (
                <meta name="twitter:description" content={seo.description} />
              )}
              {seo.image && <meta name="twitter:image" content={seo.image} />}
            </Helmet>
          </>
        )
      }}
    />
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  location: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  type: PropTypes.oneOf(["place", "article", "product"]),
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  type: "article",
  location: null,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultTitle_en: title_en
        titleTemplate
        defaultDescription: description
        defaultDescription_en: description_en
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
