import React from "react";
import {
  Drawer,
  DrawerBody,
  IconButton,
  useDisclosure,
  DrawerOverlay,
  DrawerContent,
} from "@chakra-ui/core";
import { MdDehaze } from "react-icons/md";
import Links from "./links";
import UserType from "../login/userType";
import { useIntl } from "gatsby-plugin-intl"

// const useRouteChanged = callback => {
//   const router = useRouter();
//   useEffect(() => {
//     const handleRouteChange = url => {
//       callback();
//       console.log("App is changing to: ", url);
//     };
//
//     router.events.on("routeChangeComplete", handleRouteChange);
//
//     return () => {
//       router.events.off("routeChangeComplete", handleRouteChange);
//     };
//   }, [router.events, callback]);
// };

const MobileNav = ({ user }) => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const intl = useIntl();
  // useRouteChanged(onClose);
  return (
    <>
      <IconButton
        display={{ xs: "inline-flex", xl: "none" }}
        aria-label={intl.formatMessage({ id: "header.navigation" })}
        fontSize="20px"
        variant="ghost"
        icon={MdDehaze}
        onClick={onToggle}
      />
      <Drawer
        size="xs"
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={0}>
            <Links
              display="flex"
              flexDirection="column"
              contentHeight="100vh"
              top="0"
              user={user}
              closeDrawer={onClose}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

MobileNav.propTypes = {
  user: UserType,
};

MobileNav.defaultProps = {
  user: null,
};

export default MobileNav;
