import React from "react";
import * as PropTypes from "prop-types";
import {
  Alert, AlertDescription, AlertIcon, AlertTitle,
} from "@chakra-ui/core";

const SessionAlert = ({ message, title }) => !!message && (
  <Alert
    mt={4}
    status={message.type}
    flexDirection="column"
    justifyContent="center"
  >
    <AlertIcon />
    <AlertTitle mr={2}>
      {message.title || title}
    </AlertTitle>
    <AlertDescription textAlign="center" fontSize="xs">
      {message.message}
    </AlertDescription>
  </Alert>
);

SessionAlert.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string.isRequired,
};

SessionAlert.defaultProps = {
  message: null,
};

export default SessionAlert;
