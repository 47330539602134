import React from "react";
import firebase from "../../../plugins/gatsby-plugin-firebase";
// import { Link } from "gatsby-plugin-intl";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  useColorMode,
  ModalHeader,
  ModalFooter,
  Button,
  Heading,
  Box,
  // List,
  // ListItem,
  // ListIcon,
} from "@chakra-ui/core";
import UserType from "./userType";
import UserAvatar from "./userAvatar";
import SessionAlert from "./sessionAlert";
import {
  GlobalDispatchContext, GlobalStateContext, actions,
} from "../../context/GlobalContextProvider";
import { useIntl } from "gatsby-plugin-intl";
// import { GiParachute } from "react-icons/gi";

const ProfileDialog = ({ user }) => {
  const intl = useIntl();
  const dispatch = React.useContext(GlobalDispatchContext);
  const state = React.useContext(GlobalStateContext);
  const { colorMode } = useColorMode();
  const bg = { light: "white", dark: "gray.700" };
  const initialRef = React.useRef();
  const [error, setError] = React.useState(null);

  const onClose = () => dispatch({ type: actions.closeDialog });

  const action = () => {
    firebase.auth().signOut()
      .then(() => onClose())
      .catch((err) => setError(err.message));
  };

  return (
    <Modal
      size="md"
      initialFocusRef={initialRef}
      onClose={onClose}
      isOpen={state.openDialog}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({ id: "login.myAccount" })}
        </ModalHeader>
        <ModalCloseButton bg={bg[colorMode]} />
        <ModalBody>
          <Box textAlign="center" my={4}>
            <UserAvatar user={user} size="2xl" mb={4} />
            <Heading as="h3" size="lg" fontWeight="200">
              {user.displayName || user.email}
            </Heading>
          </Box>
          {/*<List spacing={3} fontSize="lg">*/}
          {/*  <ListItem>*/}
          {/*    <Link to="/pilot-records">*/}
          {/*      <ListIcon icon={GiParachute} color="green.500" />*/}
          {/*      {intl.formatMessage({ id: "pilots.titleRecords" })}*/}
          {/*    </Link>*/}
          {/*  </ListItem>*/}
          {/*</List>*/}
          <SessionAlert
            title={intl.formatMessage({ id: "login.loginFailed" })}
            error={error}
          />
        </ModalBody>
        <ModalFooter>
          <Button variantColor="cyan" variant="solid" ml={2} onClick={action}>
            {intl.formatMessage({ id: "login.logout" })}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

ProfileDialog.propTypes = {
  user: UserType.isRequired,
};

export default ProfileDialog;
